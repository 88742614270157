@import "src/styles/variables";
@import "~@ui/styles/variables";

.page {
    background: $color-page-background;
    color: $color-title-active;
}

.footer {
    background: white;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
}